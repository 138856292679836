/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-left': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.807 14.989L19.7 9.096a1.667 1.667 0 10-2.357-2.357l-7.071 7.071a1.666 1.666 0 000 2.357l.01.01.013.014 7.071 7.07a1.667 1.667 0 102.357-2.356l-5.916-5.916z" _fill="#fff"/>'
  }
})
